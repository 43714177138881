import { animate, animateChild, query, style, transition, trigger } from '@angular/animations';
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	EventEmitter,
	HostBinding,
	Inject,
	Input,
	OnDestroy,
	OnInit,
	Optional,
	Output,
	TemplateRef,
	ViewChild,
} from '@angular/core';
import {
	distinctUntilFulfilmentChanged,
	FulfilmentBarComponent,
	FulfilmentState,
	FulfilmentStoreService,
} from '@woolworthsnz/fulfilment';
import { BasketService, ChangeOrderService, ShellService } from '@woolworthsnz/shop';
import {
	AppSettingsService,
	BreakPointService,
	CutoutModalComponent,
	Device,
	EmbeddedVisibilityDirective,
	FeatureService,
	FlagKey,
	FlagService,
	MaybeExternalLinkDirective,
	NotificationType,
	ShopperService,
	THEME_CONSTANTS,
	TrackEventDirective,
	TrackingEvent,
	TRADER_BASE_URL,
} from '@woolworthsnz/styleguide';
import { ContextResponse, EdrBalanceResponseV2, OneCardResponse } from '@woolworthsnz/trader-api';
import { filter, Observable, of, Subject, take, zip } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { GlobalNavSettingsService } from '../../services/global-nav-settings.service';
import { NavItemsService } from '../../services/nav-items.service';
import { QuickNavComponent } from '../quick-nav/quick-nav.component';
import { PortalModule } from '@angular/cdk/portal';
import { SearchComponent } from '../search/search.component';
import { BrowseNavComponent } from '../browse-nav/browse-nav.component';
import { MobileNavComponent } from '../mobile-nav/mobile-nav.component';
import { ChangeOrderBarComponent } from '../change-order-bar/change-order-bar.component';
import { BasketTotalsComponent } from '../basket-totals/basket-totals.component';
import { MainNavComponent } from '../main-nav/main-nav.component';
import { RouterLink } from '@angular/router';
import { HeaderComponent } from '../header/header.component';
import { FamilyBarComponent } from '../family-bar/family-bar.component';
import { AsyncPipe, NgClass, NgIf, NgStyle, NgTemplateOutlet } from '@angular/common';
import {
	EDRCelebratoryModalComponent,
	EDRCelebratoryModalService,
	EDRFireworksComponent,
	EDRRewardsHeaderComponent,
	EverydayRewardsFacade,
	PointsBalanceHeaderType,
} from '@woolworthsnz/everyday-rewards';
import EnabledFeaturesEnum = ContextResponse.EnabledFeaturesEnum;
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

const animationDuration = THEME_CONSTANTS.transitions.short;
const WOOLIES_LOGO_HEIGHT = 60;
const COUNTDOWN_LOGO_HEIGHT = 70;

interface Logo {
	subfolder: string;
	file: string;
	height: number;
}

@Component({
	selector: 'global-nav',
	templateUrl: './nav.component.html',
	styleUrls: ['./nav.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [
		trigger('preventInitialChildAnimations', [transition(':enter', [query(':enter', [], { optional: true })])]),
		trigger('fadeInOut', [
			transition(':enter', [
				style({ opacity: 0 }),
				animate(`${animationDuration}  ${THEME_CONSTANTS.easings.default}`, style({ opacity: 1 })),
			]),
			transition(':leave', [
				style({ opacity: 1 }),
				animate(`${animationDuration}  ${THEME_CONSTANTS.easings.default}`, style({ opacity: 0 })),
				// Basket totals child animation is breaking without this
				query('@*', [animateChild()], { optional: true, limit: 5 }),
			]),
		]),
		trigger('slideUpDown', [
			transition(':enter', [
				// 100px currently the largest height of the midnav and subnav together, so is just
				// enough to slide up (or out from) under the main-header
				style({ transform: 'translateY(-100px)', zIndex: 700 }),
				animate(
					`${animationDuration} ${THEME_CONSTANTS.easings['ease-in']}`,
					style({ transform: 'translateY(0px)' })
				),
			]),
			transition(':leave', [
				style({ transform: 'translateY(0px)', zIndex: 700 }),
				animate(
					`${animationDuration} ${THEME_CONSTANTS.easings['ease-out']}`,
					style({ transform: 'translateY(-100px)' })
				),
			]),
		]),
	],
	standalone: true,
	imports: [
		NgIf,
		FamilyBarComponent,
		NgClass,
		EmbeddedVisibilityDirective,
		HeaderComponent,
		MaybeExternalLinkDirective,
		TrackEventDirective,
		RouterLink,
		MainNavComponent,
		NgTemplateOutlet,
		BasketTotalsComponent,
		FulfilmentBarComponent,
		QuickNavComponent,
		ChangeOrderBarComponent,
		MobileNavComponent,
		BrowseNavComponent,
		NgStyle,
		SearchComponent,
		PortalModule,
		CutoutModalComponent,
		AsyncPipe,
		EDRRewardsHeaderComponent,
		EDRCelebratoryModalComponent,
		EDRFireworksComponent,
	],
})
export class NavComponent implements OnInit, AfterViewInit, OnDestroy {
	@Input() trolleyLink: string;
	@Input() mainNav: any;
	@Input() mobileNav: any;
	@Input() timeslotRoute: string;
	@Input() simplified: boolean;
	@Input() externalLinksInCurrentTab = false;
	@Input() disableNewNavItemIndication = false;
	@Output() logout = new EventEmitter();
	@ViewChild('subnav') subnav: ElementRef;
	@ViewChild('cutoutModal') public cutoutModalTemplate: TemplateRef<CutoutModalComponent>;
	@ViewChild('edrCelebratoryModal') public edrCelebratoryModalTemplate: TemplateRef<EDRCelebratoryModalComponent>;
	@ViewChild(QuickNavComponent) quickNavComponent: QuickNavComponent;

	@HostBinding('@preventInitialChildAnimations')
	preventInitialChildAnimations = true;
	oneCardBalance$: Observable<OneCardResponse | undefined>;
	shouldShowUnderlay = false;
	trackingEvent = TrackingEvent;
	notificationType = NotificationType;
	isSmallScreen$: Observable<boolean>;
	isLargeScreen$: Observable<boolean>;
	isChangingOrder$: Observable<boolean>;
	isMobileDevice$: Observable<boolean>;
	amountToPay$: Observable<string>;
	totalItems$: Observable<number>;
	darkTheme: boolean;
	public modalRewardsTarget: ElementRef<HTMLLIElement | HTMLButtonElement>;
	public desktopLogo$: Observable<{ url: string; height: number }>;
	public mobileLogoURL$: Observable<string>;
	public navExperiment7VariationKey$: Observable<string>;
	public isNavExperiment7VariationActive$: Observable<boolean>;
	public edrBalance$: Observable<EdrBalanceResponseV2 | undefined>;
	fulfilmentStoreState$ = this.fulfilmentStoreService.state$.pipe(
		takeUntilDestroyed(),
		distinctUntilFulfilmentChanged()
	);

	/** Enum reference to be used in the template **/
	public pointsBalanceHeaderType = PointsBalanceHeaderType;

	public isWindowEmbedded: boolean;
	private destroyed$: Subject<boolean> = new Subject();

	constructor(
		private changeOrderService: ChangeOrderService,
		private breakPointService: BreakPointService,
		private shopperService: ShopperService,
		private shellService: ShellService,
		private flagService: FlagService,
		private appSettingsService: AppSettingsService,
		private globalNavSettings: GlobalNavSettingsService,
		private featureService: FeatureService,
		private basketService: BasketService,
		private fulfilmentStoreService: FulfilmentStoreService,
		private edrCelebratoryModalService: EDRCelebratoryModalService,
		private navItemsService: NavItemsService,
		private edrFacade: EverydayRewardsFacade,
		@Optional() @Inject(TRADER_BASE_URL) private traderBaseUrl: string
	) {}

	@HostBinding('class.global-embedded-nav')
	get isEmbeddedInApp(): boolean {
		return this.appSettingsService.getSetting('isEmbeddedInApp');
	}

	get baseLink(): string {
		return `${this.traderBaseUrl ?? ''}/`;
	}

	public ngAfterViewInit(): void {
		this.modalRewardsTarget = this.quickNavComponent?.rewardsModalTarget;
		this.shopperService.state$
			.pipe(
				filter((state) => Boolean(state.isLoggedIn)),
				take(2)
			)
			.subscribe(() => {
				this.edrCelebratoryModalService.tryOpenCelebratoryModal(this.edrCelebratoryModalTemplate);
			});
	}

	ngOnDestroy(): void {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	public ngOnInit(): void {
		this.navExperiment7VariationKey$ = this.flagService
			.getVariationKey(FlagKey.globalNavExperiment)
			.pipe(map((key) => (!this.simplified ? key : 'off')));
		this.isNavExperiment7VariationActive$ = this.navExperiment7VariationKey$.pipe(
			map((variantKey) => ['v1_a', 'v1_b', 'v1_c'].includes(variantKey))
		);

		this.edrBalance$ = this.edrFacade.edrBalance$;

		this.oneCardBalance$ = this.shopperService.state$.pipe(map((state) => state.oneCardBalance));

		this.isMobileDevice$ = this.breakPointService.device$.pipe(map((device) => device === Device.MOBILE));

		this.isSmallScreen$ = this.breakPointService.device$.pipe(
			map((device) => device !== Device.LARGE && device !== Device.XLARGE)
		);

		this.isLargeScreen$ = this.breakPointService.device$.pipe(
			map((device) => [Device.TABLET, Device.DESKTOP, Device.LARGE, Device.XLARGE].includes(device))
		);

		this.isChangingOrder$ = this.changeOrderService.select('isChangingOrder');

		if (!this.shellService.hasInitialised) {
			this.shellService.getApplicationShell().pipe(takeUntil(this.destroyed$)).subscribe();
		}

		this.globalNavSettings.update({
			externalLinksInCurrentTab: this.externalLinksInCurrentTab,
			disableNewNavItemIndication: this.disableNewNavItemIndication,
		});

		this.amountToPay$ = this.basketService.state$.pipe(map((s) => s.subtotal ?? '$0.00'));
		this.totalItems$ = this.basketService.state$.pipe(map((s) => s.totalItems ?? 0));

		this.fulfilmentStoreState$.subscribe(
			(state: FulfilmentState) => (this.darkTheme = state.expressFulfilment?.isExpressSlot ?? false)
		);

		if (!this.navItemsService.isInitialised) {
			this.navItemsService.init();
		}

		this.setDesktopLogoURL();

		this.mobileLogoURL$ = zip(
			this.featureService.isEnabled('Brickify'),
			this.featureService.isEnabled('ChristmasWapple')
		).pipe(
			switchMap(([brickify, christmasWapple]) => {
				switch (true) {
					case brickify:
						return of(`/images/global-nav/brickify/icon-wapple-brickified.svg`);
					case christmasWapple:
						return of(`/images/global-nav/christmaswapple/icon-wapple-xmas.svg`);
					default:
						return of('/images/global-nav/mobile/icon-wapple.svg');
				}
			})
		);
	}

	public openMobileNav(): void {
		this.shellService.toggleMobileNav('show');
	}

	public handleMenuChange(menuOpen: boolean): void {
		this.shouldShowUnderlay = menuOpen;
	}

	public handleLogout(): void {
		this.logout.emit();
	}

	setDesktopLogoURL(): void {
		this.desktopLogo$ = zip(
			this.featureService.isEnabled(EnabledFeaturesEnum.Brickify),
			this.featureService.isEnabled(EnabledFeaturesEnum.ChristmasWapple),
			this.featureService.isEnabled(EnabledFeaturesEnum.RebrandInHeaderAndFooter)
		).pipe(
			switchMap(([brickify, christmasWapple, rebrandInHeaderAndFooter]) => {
				const defaultLogo: Logo = {
					subfolder: 'header',
					file: 'countdown-desktop-logo.svg',
					height: COUNTDOWN_LOGO_HEIGHT,
				};

				const conditionalLogos: Array<{
					condition: boolean;
					logo: Logo;
				}> = [
					{
						condition: brickify,
						logo: {
							subfolder: 'brickify',
							file: 'countdown-desktop-logo-brickified.svg',
							height: COUNTDOWN_LOGO_HEIGHT,
						},
					},
					{
						condition: christmasWapple && rebrandInHeaderAndFooter,
						logo: {
							subfolder: 'christmaswapple',
							file: 'woolworths-desktop-logo-xmas.svg',
							height: WOOLIES_LOGO_HEIGHT,
						},
					},
					{
						condition: christmasWapple && !rebrandInHeaderAndFooter,
						logo: {
							subfolder: 'christmaswapple',
							file: 'countdown-desktop-logo-xmas.svg',
							height: COUNTDOWN_LOGO_HEIGHT,
						},
					},
					{
						condition: rebrandInHeaderAndFooter,
						logo: {
							subfolder: 'header',
							file: 'woolworths-desktop-logo.svg',
							height: WOOLIES_LOGO_HEIGHT,
						},
					},
					{
						condition: !rebrandInHeaderAndFooter,
						logo: defaultLogo,
					},
				];

				const logo = conditionalLogos.find(({ condition }) => condition)?.logo ?? defaultLogo;

				return of({
					url: `/images/global-nav/${logo.subfolder}/${logo.file}`,
					height: logo.height,
				});
			})
		);
	}
}
